<template>
  <div class="page flex-col">
    <div class="group_1 flex-col">
      <span class="text_1">基于微信小程序的数字化招商系统</span>
      <span class="text_2">智慧招商引资数字化平台</span>
      <span class="paragraph_1"
        >涵盖资产管理、招商管理、项目管理、运营管理的智慧招商引资系统平台，助力乡村振兴，地方资源招商引资，大幅提高项目运营方收入，<br />让招商运营智慧化高效化</span
      >
      <div class="section_1 flex-col">
        <div class="text-wrapper_1 flex-col" @click="freeClick">
          立即咨询体验
        </div>
      </div>
    </div>
    <div class="box_4 flex-col">
      <div class="text-group_7 flex-col justify-between">
        <span class="text_4">系统特色</span>
        <span class="text_5"
          >依托于新型互联网技术，打造智能高效的智慧招商解决方案</span
        >
      </div>
      <div class="group_16 flex-row">
        <div class="text-wrapper_20 flex-col">
          <span class="text_6">招商信息数据化</span>
          <span class="paragraph_2"
            >建设招商项目储备库<br />完善招商基础工作标准化体系<br />建立人工智能的智慧招商机制</span
          >
          <span class="text_7">成果看板可视化</span>
        </div>
        <img
          class="image_1"
          referrerpolicy="no-referrer"
          src="../assets/8.png"
        />
        <div class="text-wrapper_21 flex-col">
          <span class="text_6">产业招商地图</span>
          <span class="paragraph_2"
            >智能产业地图，招商可视化<br />精准对接客商，精准化招商</span
          >
          <span class="text_9">招商大数据</span>
        </div>
      </div>
      <div class="text-wrapper_22 flex-row justify-between">
        <span class="paragraph_4"
          >招商成果可视化<br />对优势产业领域及项目、机构等数据分析<br />精确展示运营数据</span
        >
        <span class="paragraph_5"
          >产业链查询检索<br />产业图谱、产业现状分析<br />潜在投资企业获取、精准招商</span
        >
      </div>
      <div class="image-text_3 flex-row" id="group_2">
        <div
          class="text-group_8 flex-col"
          :class="TwoShow ? 'animate__animated animate__slideInLeft' : ''"
        >
          <span class="text_10">智能化、移动化、数字化的招商系统</span>
          <span class="paragraph_6"
            >多功能融合统一管理，智慧办公，实现高效、智能化、<br />数字化，的智慧招商管理</span
          >
          <div class="text-wrapper_23 flex-row justify-between">
            <div>
              <img
                class="thumbnail_1"
                referrerpolicy="no-referrer"
                src="../assets/9.png"
              />
              <span class="text_11">招募主题</span>
            </div>
            <div>
              <img
                class="thumbnail_1"
                referrerpolicy="no-referrer"
                src="../assets/9.png"
              />
              <span class="text_11">投资指南</span>
            </div>
          </div>
          <div class="text-wrapper_23 flex-row justify-between">
            <div>
              <img
                class="thumbnail_1"
                referrerpolicy="no-referrer"
                src="../assets/9.png"
              />
              <span class="text_11">重点项目</span>
            </div>
            <div>
              <img
                class="thumbnail_1"
                referrerpolicy="no-referrer"
                src="../assets/9.png"
              />
              <span class="text_11">智能选址</span>
            </div>
          </div>
          <div class="text-wrapper_23 flex-row justify-between">
            <div>
              <img
                class="thumbnail_1"
                referrerpolicy="no-referrer"
                src="../assets/9.png"
              />
              <span class="text_11">旅游资源</span>
            </div>
            <div>
              <img
                class="thumbnail_1"
                referrerpolicy="no-referrer"
                src="../assets/9.png"
              />
              <span class="text_11">主导产业</span>
            </div>
          </div>
          <div class="text-wrapper_23 flex-row justify-between">
            <div>
              <img
                class="thumbnail_1"
                referrerpolicy="no-referrer"
                src="../assets/9.png"
              />
              <span class="text_11">乡村振兴</span>
            </div>
            <div>
              <img
                class="thumbnail_1"
                referrerpolicy="no-referrer"
                src="../assets/9.png"
              />
              <span class="text_11">特色经济</span>
            </div>
          </div>
        </div>
        <img
          class="image_2"
          referrerpolicy="no-referrer"
          src="../assets/11.png"
          :class="TwoShow ? 'animate__animated animate__slideInRight' : ''"
        />
      </div>
      <div class="group_4 flex-col">
        <div class="text-group_9 flex-col justify-between">
          <span class="text_19">产品矩阵</span>
          <span class="text_20"
            >涵盖资产管理、招商管理、项目管理、运营管理的智慧招商引资系统平台</span
          >
        </div>
        <div class="box_5 flex-row justify-between">
          <div class="group_6 flex-col">
            <img
              class="image-wrapper_9"
              referrerpolicy="no-referrer"
              src="../assets/2.png"
            />
            <span class="text_21">招商管理</span>
            <span class="paragraph_7"
              >将招商工作数字化管理，通过系统实现招募主题发布，招商资源公布，招商公开化</span
            >
          </div>
          <div class="group_6 flex-col">
            <img
              class="image-wrapper_9"
              referrerpolicy="no-referrer"
              src="../assets/3.png"
            />
            <span class="text_21">项目管理</span>
            <span class="paragraph_7"
              >在线发布管控项目资源，涵盖全项目类型，实现项目信息数据化</span
            >
          </div>
          <div class="group_6 flex-col">
            <img
              class="image-wrapper_9"
              referrerpolicy="no-referrer"
              src="../assets/4.png"
            />
            <span class="text_21">资源管理</span>
            <span class="paragraph_7"
              >资产资源可视化，智能资源产业地图让招商可视化，精准化招商</span
            >
          </div>
        </div>
        <div class="box_6 flex-row justify-between">
          <div class="group_6 flex-col">
            <img
              class="image-wrapper_9"
              referrerpolicy="no-referrer"
              src="../assets/5.png"
            />
            <span class="text_21">主导产业</span>
            <span class="paragraph_7"
              >主导产业展示，产业链查询检索，产业现状分析，精准化招商</span
            >
          </div>
          <div class="group_6 flex-col">
            <img
              class="image-wrapper_9"
              referrerpolicy="no-referrer"
              src="../assets/6.png"
            />
            <span class="text_21">地方政策</span>
            <span class="paragraph_7"
              >地方政策公布，潜在商机获取，帮助入驻商家轻松入驻</span
            >
          </div>
          <div class="group_6 flex-col">
            <img
              class="image-wrapper_9"
              referrerpolicy="no-referrer"
              src="../assets/7.png"
            />
            <span class="text_21">智慧选址</span>
            <span class="paragraph_7"
              >利用大数据分析工具，更科学高效的展示招商信息，提供招商服务</span
            >
          </div>
        </div>
      </div>
      <div class="group_13 flex-col">
        <span class="text_27">产品服务</span>
        <div class="section_5 flex-row justify-between">
          <div
            class="flex-col"
            :class="serveIndex == 1 ? 'text-wrapper_9' : 'text-wrapper_10'"
            @click="serveClick(1)"
          >
            招商地图
          </div>
          <div
            class="flex-col"
            :class="serveIndex == 2 ? 'text-wrapper_9' : 'text-wrapper_10'"
            @click="serveClick(2)"
          >
            产业链系统
          </div>
          <div
            class="flex-col"
            :class="serveIndex == 3 ? 'text-wrapper_9' : 'text-wrapper_10'"
            @click="serveClick(3)"
          >
            招商引资管理系统
          </div>
        </div>
        <div
          class="section_6 flex-row justify-between"
          id="group_1"
          v-if="serveIndex == 1"
        >
          <img
            class="image_3"
            referrerpolicy="no-referrer"
            src="../assets/10.png"
            :class="OneShow ? 'animate__animated animate__slideInLeft' : ''"
          />
          <div
            class="block_4 flex-col"
            :class="OneShow ? 'animate__animated animate__slideInRight' : ''"
          >
            <span class="text_31">招商地图</span>
            <span class="paragraph_13"
              >对外，以GIS地图、VR等多种可视化形式，在线宣传招商信息，同时借助招商网络的优质流量，扩大招商引资线索来源，增强<br />宣传效果，树立招商品牌。<br />对内，汇集当地招商资源数据建立数据库，通过载体资源管理、载体智能匹配等功能，实现载体资源统筹管理，增强招商能力。</span
            >
            <div class="box_3 flex-col">
              <div class="text-wrapper_12 flex-col" @click="freeClick">
                立即咨询体验
              </div>
            </div>
          </div>
        </div>
        <!--  -->
        <div
          class="section_6 flex-row justify-between"
          id="group_1"
          v-if="serveIndex == 2"
        >
          <img
            class="image_3"
            referrerpolicy="no-referrer"
            src="../assets/12.png"
            :class="OneShow ? 'animate__animated animate__slideInLeft' : ''"
          />
          <div
            class="block_4 flex-col"
            :class="OneShow ? 'animate__animated animate__slideInRight' : ''"
          >
            <span class="text_31">产业链招商系统</span>
            <span class="paragraph_13"
              >针对主导产业或核心企业进行产业链上下游梳理，制作产业链图谱，并通过数据挖掘生成对应企业名录，通过可视化展示快速定位缺失环节、智能推荐企业，围绕建链、补链、强链、延链，协助推动地区产业链高质量发展。</span
            >
            <div class="box_3 flex-col">
              <div class="text-wrapper_12 flex-col" @click="freeClick">
                立即咨询体验
              </div>
            </div>
          </div>
        </div>
        <!--  -->
        <div
          class="section_6 flex-row justify-between"
          id="group_1"
          v-if="serveIndex == 3"
        >
          <img
            class="image_3"
            referrerpolicy="no-referrer"
            src="../assets/13.png"
            :class="OneShow ? 'animate__animated animate__slideInLeft' : ''"
          />
          <div
            class="block_4 flex-col"
            :class="OneShow ? 'animate__animated animate__slideInRight' : ''"
          >
            <span class="text_31">项目全生命周期管理系统</span>
            <span class="paragraph_13"
              >涵盖在谈、签约、推进、落地、履约等项目全生命周期环节，通过任务督办、绩效考核、数据可视化统计等功能，助力项目高效推进、团队规范管理，为招商决策提供数据支撑，提升招商引资工作效率。</span
            >
            <div class="box_3 flex-col">
              <div class="text-wrapper_12 flex-col" @click="freeClick">
                立即咨询体验
              </div>
            </div>
          </div>
        </div>
        <!--  -->
      </div>
    </div>
    <!--  -->
    <div class="section_32 flex-row">
      <div class="box_47 flex-col">
        <img
          class="image_42"
          referrerpolicy="no-referrer"
          src="../assets/35.png"
        />
        <span class="text_104">河南品讯网络科技有限公司</span>
        <span class="text_105">0379-61118888</span>
        <span class="paragraph_25"
          >地址：河南省洛阳市老城区青年创业大厦24层</span
        >
      </div>
      <div class="box_48 flex-col"></div>
      <div class="text-wrapper_38 flex-col">
        <span class="text_106">产品体系</span>
        <span
          class="text_107"
          @click="goOther('http://www.hnpinxun.cn/yuanchengtakan.html')"
          >远程踏勘管理系统
        </span>
        <span class="text_107" @click="goOther('http://yt.hnpinxun.cn/')"
          >友推云·电商SaaS平台</span
        >
        <span class="text_107" @click="goOther('https://fastrms.hnpinxun.cn/')"
          >视频资源管理AI工具</span
        >
        <span class="text_107" @click="goOther('http://hsxx.hnpinxun.cn/')"
          >亥时学习平台</span
        >
        <span class="text_107" @click="goOther('https://jifen.hnpinxun.cn/')"
          >文明积分银行</span
        >
        <span class="text_107" @click="goOther('https://linli.hnpinxun.cn/')"
          >邻里中心智慧平台</span
        >
      </div>
      <div class="text-wrapper_38 flex-col">
        <span class="text_106"></span>
        <span class="text_107" @click="goOther('https://gcsc.yt.hnpinxun.cn/')"
          >工厂生产管理系统</span
        >
        <span class="text_107" @click="goOther('https://jdgl.yt.hnpinxun.cn/')"
          >酒店系统</span
        >
        <span class="text_107" @click="goOther('https://dysbd.yt.hnpinxun.cn/')"
          >党员双报到</span
        >
        <span class="text_107" @click="goOther('https://hcjy.yt.hnpinxun.cn/')"
          >货车道路救援系统</span
        >
        <span class="text_107" @click="goOther('https://zs.yt.hnpinxun.cn/')"
          >招商宣传</span
        >
        <span class="text_107" @click="goOther('https://hw.yt.hnpinxun.cn/')"
          >智慧环卫综合平台</span
        >
      </div>
      <div class="text-wrapper_38 flex-col">
        <span class="text_106"></span>
        <span class="text_107" @click="goOther('https://jz.yt.hnpinxun.cn/')"
          >家政行业公共服务平台</span
        >
      </div>

      <div class="box_49 flex-col"></div>
      <div class="box_50 flex-col justify-between">
        <img
          class="image_43"
          referrerpolicy="no-referrer"
          src="../assets/33.png"
        />
        <span class="text_120">微信小程序</span>
      </div>
      <div class="box_51 flex-col justify-between">
        <img
          class="image_44"
          referrerpolicy="no-referrer"
          src="../assets/34.png"
        />
        <span class="text_121">微信公众号</span>
      </div>
    </div>
    <div class="text-wrapper_19 flex-col">
      <span class="text_44"
        >Copyright&nbsp;©&nbsp;2024&nbsp;河南品讯网络科技有限公司&nbsp;版权所有豫ICP备18009817号</span
      >
    </div>

    <!--  -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      serveIndex: 1,
      OneShow: false,
      TwoShow: false,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  BeforeUnloadEvent() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    freeClick() {
      window.open("http://www.hnpinxun.cn/#/", "_blank");
    },
    serveClick(e) {
      this.serveIndex = e;
    },
    handleScroll() {
      const scrollPosition = window.scrollY || window.pageYOffset;
      var group1Top = document.getElementById("group_1").offsetTop;
      const isGroupVisible =
        scrollPosition >= group1Top - window.innerHeight &&
        scrollPosition < group1Top;

      if (isGroupVisible && !this.OneShow) {
        this.OneShow = true;
      } else if (scrollPosition === 0) {
        this.OneShow = false;
      }
      //
      var group2Top = document.getElementById("group_2").offsetTop;
      const isGroupVisible2 =
        scrollPosition >= group2Top - window.innerHeight &&
        scrollPosition < group2Top;

      if (isGroupVisible2 && !this.TwoShow) {
        this.TwoShow = true;
      } else if (scrollPosition === 0) {
        this.TwoShow = false;
      }
    },
    goOther(e) {
      window.open(e, "_blank");
    },
  },
};
</script>

<style scoped>
.page {
  background-color: rgba(255, 255, 255, 1);
  position: relative;
  overflow: hidden;
}

.group_1 {
  width: 100vw;
  height: 34.38vw;
  background: url("/src/assets/1.png") 100% no-repeat;
  background-size: 100% 100%;
}

.text_1 {
  width: 25vw;
  height: 1.67vw;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 1.66vw;
  font-family: PingFang SC-Regular;
  font-weight: normal;
  text-align: center;
  white-space: nowrap;
  margin: 8.33vw 0 0 18.75vw;
}

.text_2 {
  width: 34.38vw;
  height: 3.13vw;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 3.12vw;
  font-family: MiSans-Medium;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  margin: 1.56vw 0 0 18.75vw;
}

.paragraph_1 {
  width: 29.07vw;
  height: 3.86vw;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 0.93vw;
  font-family: PingFang SC-Regular;
  font-weight: normal;
  text-align: left;
  margin: 1.56vw 0 0 18.75vw;
}

.section_1 {
  box-shadow: 0px 4px 6px 0px rgba(47, 108, 255, 0.35);
  height: 2.61vw;
  width: 8.34vw;
  margin: 2.6vw 0 9.06vw 18.75vw;
}

.text-wrapper_1 {
  background-color: rgba(47, 108, 255, 1);
  border-radius: 5px;
  height: 2.61vw;
  width: 8.34vw;
  color: rgba(255, 255, 255, 1);
  font-size: 0.83vw;
  font-family: PingFang SC-Regular;
  font-weight: normal;
  text-align: center;
  line-height: 2.61vw;
  cursor: pointer;
}
.text-wrapper_1:hover {
  transform: scale(1.1);
}

.box_4 {
  position: relative;
  width: 100vw;
  height: 169.8vw;
  margin-bottom: 0.06vw;
}

.text-group_7 {
  width: 24.38vw;
  height: 5.11vw;
  margin: 45.83vw 0 0 37.81vw;
}

.text_4 {
  width: 12.5vw;
  height: 3.13vw;
  overflow-wrap: break-word;
  color: rgba(47, 108, 255, 1);
  font-size: 3.12vw;
  font-family: MiSans-Medium;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  margin-left: 5.94vw;
}

.text_5 {
  width: 24.38vw;
  height: 0.94vw;
  overflow-wrap: break-word;
  color: rgba(102, 102, 102, 1);
  font-size: 0.93vw;
  font-family: PingFang SC-Regular;
  font-weight: normal;
  text-align: center;
  white-space: nowrap;
  margin-top: 1.05vw;
}

.group_16 {
  width: 51.52vw;
  height: 28.13vw;
  margin: 4.16vw 0 0 24.21vw;
}

.text-wrapper_20 {
  width: 10.16vw;
  height: 28.13vw;
}

.text_6 {
  width: 8.75vw;
  height: 1.25vw;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 1.25vw;
  font-family: PingFang SC-Medium;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
}

.paragraph_2 {
  width: 10.16vw;
  height: 3.39vw;
  overflow-wrap: break-word;
  color: rgba(102, 102, 102, 1);
  font-size: 0.78vw;
  font-family: PingFang SC-Regular;
  font-weight: normal;
  text-align: left;
  margin-top: 1.05vw;
}

.text_7 {
  width: 8.75vw;
  height: 1.25vw;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 1.25vw;
  font-family: PingFang SC-Medium;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  margin-top: 21.2vw;
}

.image_1 {
  width: 30.68vw;
  height: 27.71vw;
  margin: 0.2vw 0 0 0.26vw;
}

.text-wrapper_21 {
  width: 9.38vw;
  height: 28.13vw;
  margin-left: 1.05vw;
}

.text_9 {
  width: 6.25vw;
  height: 1.25vw;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 1.25vw;
  font-family: PingFang SC-Medium;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  margin-top: 21vw;
}

.text-wrapper_22 {
  width: 52.3vw;
  height: 3.39vw;
  margin: 1.04vw 0 0 24.21vw;
}

.paragraph_4 {
  width: 14.07vw;
  height: 3.39vw;
  overflow-wrap: break-word;
  color: rgba(102, 102, 102, 1);
  font-size: 0.78vw;
  font-family: PingFang SC-Regular;
  font-weight: normal;
  text-align: left;
}

.paragraph_5 {
  width: 10.16vw;
  height: 3.39vw;
  overflow-wrap: break-word;
  color: rgba(102, 102, 102, 1);
  font-size: 0.78vw;
  font-family: PingFang SC-Regular;
  font-weight: normal;
  text-align: left;
}

.image-text_3 {
  position: relative;
  width: 60.42vw;
  height: 26.57vw;
  margin: 44.27vw 0 11.3vw 19.79vw;
}

.text-group_8 {
  width: 33.65vw;
  height: 22.24vw;
  margin-top: 2.61vw;
}

.text_10 {
  width: 33.65vw;
  height: 2.3vw;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 2.29vw;
  font-family: PingFang SC-Semibold;
  font-weight: 600;
  text-align: left;
  white-space: nowrap;
}

.paragraph_6 {
  width: 30vw;
  height: 3.34vw;
  overflow-wrap: break-word;
  color: rgba(102, 102, 102, 1);
  font-size: 1.25vw;
  font-family: PingFang SC-Regular;
  font-weight: normal;
  text-align: left;
  margin-top: 1.57vw;
}

.text-wrapper_23 {
  width: 18.18vw;
  height: 1.46vw;
  margin: 2.65vw 0 0 0vw;
}

.thumbnail_1 {
  height: 1.57vw;
  width: 1.57vw;
  vertical-align: text-bottom;
  margin-right: 0.77vw;
}

.text_11 {
  width: 5.84vw;
  height: 1.46vw;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 1.45vw;
  font-family: PingFang SC-Regular;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
}

.image_2 {
  width: 26.46vw;
  height: 26.57vw;
}

.group_4 {
  background-color: rgba(247, 250, 255, 1);
  position: absolute;
  left: 0.06vw;
  top: 0;
  width: 100vw;
  height: 41.67vw;
}

.text-group_9 {
  width: 28.13vw;
  height: 5.11vw;
  margin: 4.16vw 0 0 35.88vw;
}

.text_19 {
  width: 12.5vw;
  height: 3.13vw;
  overflow-wrap: break-word;
  color: rgba(47, 108, 255, 1);
  font-size: 3.12vw;
  font-family: MiSans-Medium;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  margin-left: 7.82vw;
}

.text_20 {
  width: 28.13vw;
  height: 0.94vw;
  overflow-wrap: break-word;
  color: rgba(102, 102, 102, 1);
  font-size: 0.93vw;
  font-family: PingFang SC-Regular;
  font-weight: normal;
  text-align: center;
  white-space: nowrap;
  margin-top: 1.05vw;
}

.box_5 {
  width: 62.5vw;
  height: 12.5vw;
  margin: 3.12vw 0 0 18.69vw;
}

.group_6 {
  background-color: rgba(255, 255, 255, 1);
  border-radius: 10px;
  width: 19.8vw;
  height: 12.5vw;
}
.group_6:hover {
  transform: translateY(-10px);
}

.image-wrapper_9 {
  height: 4.17vw;
  width: 4.17vw;
  margin: 1.56vw 0 0 7.81vw;
}

.text_21 {
  width: 4.17vw;
  height: 1.05vw;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 1.04vw;
  font-family: PingFang SC-Medium;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  margin: 1.04vw 0 0 7.81vw;
}

.paragraph_7 {
  width: 13.86vw;
  height: 1.98vw;
  overflow-wrap: break-word;
  color: rgba(153, 153, 153, 1);
  font-size: 0.72vw;
  font-family: PingFang SC-Regular;
  font-weight: normal;
  text-align: center;
  margin: 1.04vw 0 1.66vw 2.96vw;
}

.group_8 {
  background-color: rgba(255, 255, 255, 1);
  border-radius: 10px;
  width: 19.8vw;
  height: 12.5vw;
  margin-left: 1.57vw;
}

.box_6 {
  width: 62.5vw;
  height: 12.5vw;
  margin: 1.56vw 0 2.7vw 18.69vw;
}

.group_13 {
  background-color: rgba(247, 250, 255, 1);
  position: absolute;
  left: 0.06vw;
  top: 91.83vw;
  width: 100vw;
  height: 36.46vw;
}

.text_27 {
  width: 12.5vw;
  height: 3.13vw;
  overflow-wrap: break-word;
  color: rgba(47, 108, 255, 1);
  font-size: 3.12vw;
  font-family: MiSans-Medium;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  margin: 4.16vw 0 0 43.75vw;
}

.section_5 {
  width: 45.84vw;
  height: 3.13vw;
  margin: 2.18vw 0 0 25.08vw;
}

.text-wrapper_9 {
  background-color: rgba(255, 255, 255, 1);
  border-radius: 10px;
  height: 3.13vw;
  margin-left: 2.61vw;
  border: 0.104vw solid rgba(47, 108, 255, 1);
  width: 13.55vw;
  color: rgba(47, 108, 255, 1);
  font-size: 1.25vw;
  font-family: PingFang SC-Medium;
  font-weight: 500;
  text-align: center;
  line-height: 3.13vw;
  cursor: pointer;
}

.text-wrapper_10 {
  background-color: rgba(255, 255, 255, 1);
  border-radius: 10px;
  height: 3.13vw;
  margin-left: 2.61vw;
  width: 13.55vw;
  color: rgba(0, 0, 0, 1);
  font-size: 1.25vw;
  font-family: PingFang SC-Medium;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  line-height: 3.13vw;
  cursor: pointer;
}

.section_6 {
  width: 59.38vw;
  height: 19.28vw;
  margin: 1.97vw 0 2.6vw 19.79vw;
}

.image_3 {
  width: 33.34vw;
  height: 19.28vw;
}

.block_4 {
  width: 23.34vw;
  height: 13.96vw;
  margin-top: 2.09vw;
}

.text_31 {
  width: 5vw;
  height: 1.25vw;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 1.25vw;
  font-family: PingFang SC-Medium;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
}

.paragraph_13 {
  width: 23.34vw;
  height: 7.5vw;
  overflow-wrap: break-word;
  color: rgba(102, 102, 102, 1);
  font-size: 0.83vw;
  font-family: PingFang SC-Regular;
  font-weight: normal;
  text-align: left;
  margin-top: 1.05vw;
  line-height: 1.24vw;
}

.box_3 {
  box-shadow: 0px 4px 6px 0px rgba(47, 108, 255, 0.35);
  height: 2.61vw;
  margin-top: 1.57vw;
  width: 8.34vw;
}

.text-wrapper_12 {
  background-color: rgba(47, 108, 255, 1);
  border-radius: 5px;
  height: 2.61vw;
  width: 8.34vw;
  color: rgba(255, 255, 255, 1);
  font-size: 0.83vw;
  font-family: PingFang SC-Regular;
  font-weight: normal;
  text-align: center;
  white-space: nowrap;
  line-height: 2.61vw;
  cursor: pointer;
}
.text-wrapper_12:hover {
  transform: scale(1.1);
}

/*  */
/*  */

.section_32 {
  background-color: rgba(34, 34, 34, 1);
  width: 100%;
  height: 17.63vw;
  justify-content: flex-center;
}

.box_47 {
  width: 14.12vw;
  height: 10.73vw;
  margin: 3.02vw 0 0 18.7vw;
}

.image_42 {
  width: 2.87vw;
  height: 2.87vw;
  margin-left: 5.58vw;
}

.text_104 {
  width: 13.96vw;
  height: 1.1vw;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 1.04vw;
  font-family: MicrosoftYaHei;
  font-weight: 700;
  text-align: left;
  white-space: nowrap;
  line-height: 1.05vw;
  margin-top: 0.73vw;
}

.text_105 {
  width: 14.02vw;
  height: 1.25vw;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 1.56vw;
  font-family: MicrosoftYaHei;
  font-weight: 700;
  text-align: left;
  white-space: nowrap;
  line-height: 1.57vw;
  margin-top: 0.94vw;
}

.paragraph_25 {
  width: 14.07vw;
  height: 1.88vw;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 0.83vw;
  font-family: MicrosoftYaHei;
  font-weight: normal;
  text-align: left;
  line-height: 1.25vw;
  margin: 1.97vw 0 0 0.05vw;
}

.box_48 {
  background-color: rgba(255, 255, 255, 0.12);
  width: 0.06vw;
  height: 10.42vw;
  margin: 3.28vw 0 0 3.26vw;
}

.text-wrapper_38 {
  width: 5.68vw;
  height: 9.38vw;
  margin: 1.9vw 0 0 5.36vw;
}

.text_106 {
  width: 3.7vw;
  height: 0.84vw;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 0.83vw;
  font-family: MicrosoftYaHei;
  font-weight: 700;
  text-align: left;
  white-space: nowrap;
  line-height: 0.84vw;
}

.text_107 {
  width: 4.8vw;
  height: 0.84vw;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 0.83vw;
  font-family: MicrosoftYaHei;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
  line-height: 0.84vw;
  margin: 1.4vw 0 0 0.05vw;
  cursor: pointer;
}
.text_107:hover {
  color: #0b74ef;
}

.text-wrapper_39 {
  width: 3.7vw;
  height: 9.43vw;
  margin: 3.8vw 0 0 5.31vw;
}

.text_111 {
  width: 3.7vw;
  height: 0.89vw;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 0.83vw;
  font-family: MicrosoftYaHei;
  font-weight: 700;
  text-align: left;
  white-space: nowrap;
  line-height: 0.84vw;
}

.text_112 {
  width: 2.66vw;
  height: 0.79vw;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 0.83vw;
  font-family: MicrosoftYaHei;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
  line-height: 0.84vw;
  margin: 1.71vw 0 0 0.05vw;
}

.text_113 {
  width: 3.55vw;
  height: 0.84vw;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 0.83vw;
  font-family: MicrosoftYaHei;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
  line-height: 0.84vw;
  margin: 1.19vw 0 0 0.05vw;
}

.text_114 {
  width: 3.6vw;
  height: 0.79vw;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 0.83vw;
  font-family: MicrosoftYaHei;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
  line-height: 0.84vw;
  margin: 1.19vw 0 0 0.05vw;
}

.box_49 {
  background-color: rgba(255, 255, 255, 0.12);
  width: 0.06vw;
  height: 10.42vw;
  margin: 3.22vw 0 0 5.41vw;
}

.box_50 {
  width: 6.78vw;
  height: 8.91vw;
  margin: 4.2vw 0 0 4.3vw;
}

.image_43 {
  width: 6.78vw;
  height: 6.78vw;
}

.text_120 {
  width: 4.48vw;
  height: 0.79vw;
  overflow-wrap: break-word;
  color: rgba(223, 223, 223, 1);
  font-size: 0.83vw;
  font-family: MicrosoftYaHei;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
  line-height: 0.84vw;
  margin: 1.35vw 0 0 1.14vw;
}

.box_51 {
  width: 6.78vw;
  height: 8.91vw;
  margin: 4.2vw 12.55vw 0 1.25vw;
}

.image_44 {
  width: 6.78vw;
  height: 6.78vw;
}

.text_121 {
  width: 4.54vw;
  height: 0.79vw;
  overflow-wrap: break-word;
  color: rgba(223, 223, 223, 1);
  font-size: 0.83vw;
  font-family: MicrosoftYaHei;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
  line-height: 0.84vw;
  margin: 1.35vw 0 0 1.14vw;
}
.text-wrapper_19 {
  background-color: rgba(59, 59, 59, 1);
  height: 3.49vw;
  margin-top: -0.05vw;
}

.text_44 {
  width: 28.6vw;
  height: 0.89vw;
  overflow-wrap: break-word;
  color: rgba(153, 153, 153, 1);
  font-size: 0.83vw;
  font-family: MicrosoftYaHei;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
  line-height: 0.84vw;
  margin: 1.35vw 0 0 35.67vw;
}
</style>
